<template>
    <div class="animated fadeIn">
            <b-card>
              <b-card-header>
                  Detail Teman Treasury
              </b-card-header>
              <b-card-body>
                  <b-row class="mb-4">
                      <b-col sm="4">
                          <h6>Point Teman Treasury</h6>
                          <div class="mb-3" v-if="this.myPoint == null"> - </div>
                          <div class="mb-3" v-else><strong>{{ this.myPoint == 0  ? this.myPoint+" Point" : this.myPoint+" Points" }}</strong></div>
                          <h6>Status</h6>
                          <div class="mb-3" v-if="this.status != null">
                              <strong>
                                  {{ this.status == null && this.referral_status == false ? 'Deactive' : 'Active' }}
                              </strong>
                          </div>
                          <div class="mb-3" v-else><strong> Refferral Code Tidak Ada</strong></div>
                      </b-col>
                      <b-col sm="4">
                        <h6>Direkrut oleh</h6>
                        <div class="mb-3" v-if="this.recruitFrom == null"> - </div>
                        <div class="mb-3" v-else> <strong v-html="this.recruitFrom"></strong></div>
                        <h6>Sahabat</h6>
                        <div class="mb-3" v-if="this.sahabat == null"> - </div>
                        <div class="mb-3" v-else><strong v-html="this.sahabat"></strong></div>
                        <h6>Kawan</h6>
                        <div class="mb-3" v-if="this.kawan == ''"> - </div>
                        <div class="mb-3" v-else> <strong v-html="this.kawan"></strong></div>
                      </b-col>
                      <b-col sm="4">
                          <h6>Bonus Gold</h6>
                          <div class="mb-3" v-if="this.goldBonus == null"> - </div>
                          <div class="mb-3" v-else><strong>{{ this.goldBonus == 0 ? this.goldBonus+" Gram" : this.goldBonus+" Grams"}}  </strong></div>
                      </b-col>
                  </b-row>
              </b-card-body>
            </b-card>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
export default {
    name: 'detail-user',
    data() {
        return {
            status: "",
            referral_code: "",
            referral_status: "",
            goldBonus: '',
            recruitFrom: '',
            sahabat: '',
            kawan: '',
            verified: '',
            myPoint: '',
            errors: {
                message: [],
                status: [],
                code: '',
                headers: [],
                form: {
                    reason: [],
                },
            },
            isLoading: false
        }
    },
    computed: {
        formStr() { return JSON.stringify(this.form, null, 4) },
        isValid() { return !this.$v.form.$anyError },
        isDirty() { return this.$v.form.$anyDirty },
        invCheck() { return 'You must accept before submitting' },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            reason: {
                required
            }
        }
    },
    created () {

        this.$http.get(`detail-tt/` + this.$route.params.id)
        .then((result) => {
            // this.user = result.data.user.id;\
            this.status = result.data.meta.data.temanTreasury;
            this.referral_code = result.data.meta.data.temanTreasury.referral_code;
            this.referral_status = result.data.meta.data.temanTreasury.status;
            this.myPoint = result.data.meta.data.temanTreasury.my_points;
            this.goldBonus = result.data.meta.data.temanTreasury.gold_bonus;
            this.referral_status = result.data.meta.data.temanTreasury.status;
            this.sahabat = result.data.meta.data.temanTreasury.recruiting_to.sahabat;
            this.kawan = result.data.meta.data.temanTreasury.recruiting_to.kawan;
            this.recruitFrom = result.data.meta.data.temanTreasury.recruit_from;
            this.verified = result.data.meta.data.temanTreasury.is_verified;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })
    },
    methods: {
        chkState(val) {
            const field = this.$v.form[val]
            return !field.$dirty || !field.$invalid
        },
        findFirstError(component = this) {
            if (component.state === false) {
                if (component.$refs.input) {
                component.$refs.input.focus()
                return true
                }
                if (component.$refs.check) {
                component.$refs.check.focus()
                return true
                }
            }
            let focused = false
            component.$children.some((child) => {
                focused = this.findFirstError(child)
                return focused
            })

            return focused
            },
        validate() {
            this.$v.$touch()
            this.$nextTick(() => this.findFirstError())
            return this.isValid
        }
    },
}
</script>

<style>
    .submt {
        width: 100%;
        height: auto;
        display: block;
    }
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
